/*
 * Helpers
 */

/*
 * Clearfix: contain floats
 * http://cssmojo.com/latest_new_clearfix_so_far/
 */
%cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */
%hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
  visibility: hidden;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
%visually-hidden,
.visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

/*
 * No style
 */

%list-nostyle {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%button-nostyle {
  display: inline-block;
  margin: 0;
  border: 0 none;
  padding: 0;
  outline: none;
  background: none;
  border-radius: 0;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  appearance: none;
  cursor: pointer;
}

%select-nostyle {
  display: inline-block;
  margin: 0;
  border: 0 none;
  padding: 0;
  outline: none;
  background: none;
  border-radius: 0;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  -moz-appearance: none; // sass-lint:disable-line no-vendor-prefixes

  &::-ms-expand {
    // sass-lint:disable-line no-vendor-prefixes
    display: none;
  }
}

%input-nostyle {
  display: inline-block;
  margin: 0;
  border: 0 none;
  padding: 0;
  outline: none;
  background: none;
  border-radius: 0;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  -moz-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  appearance: none;
}

/*
 * Font weight
 */

%fw-thin {
  font-weight: 100;
}

%fw-light {
  font-weight: 200;
}

%fw-normal {
  font-weight: 400;
}

%fw-medium {
  font-weight: 500;
}

%fw-bold {
  font-weight: 700;
}

/*
 * Text align/transform
 */

%text-center {
  text-align: center;
}

%text-right {
  text-align: right;
}

%text-uppercase {
  text-transform: uppercase;
}

%text-nodecoration {
  text-decoration: none;
}

%shadow {
  box-shadow: 0 20px 60px rgba($c-black, 0.1);
}

%border-radius {
  border-radius: 10px;
}
